/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import PropTypes from 'prop-types';
import { Layout, Container } from 'layouts';
import Helmet from 'react-helmet';
import Constants from '../../static/Constants';
import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
// import { Link } from 'gatsby';
import getHeaderLInks from '../shared/headerLinks';
import theme from '../../config/theme';
import GridContainer from '../components/Grid/GridContainer.jsx';
import { isArray, isEmpty } from 'lodash';

const ParagraphContent = css`
ul{
text-align: left;
}
  p{
    color: ${theme.fontColor.body};
    font-size: ${theme.fontSize.body};
    line-height: 1.55em;
    text-align: justify;
    padding:0px;
  span{
    font-weight: 600;
    color: #3c4858;
  }
  }
  h1 {
    color: #3C4858;
    margin:40px 0px;
    margin-bottom: 15px;
    font-size: ${theme.fontSize.heading};
    text-align:center;
    span
    {
      color: #00acc1;
    }
  }
  h2 {
    color: #3C4858;
    margin:40px 0px;
    margin-bottom: 15px;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.55rem;
    text-align:left;
    span
    {
      color: #00acc1;
    }
  }
  .highlited {
    background: #ffff0091;
  }
  h4 {
    color: #3C4858;
    margin:40px 0px;
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 1.20rem;
    text-align:left;
  }
  @media (max-width: 1024px) {

    p {
      padding:0px;
    }
    h4 {
      line-height: 40px;
    }
  }
  @media (max-width: 420px) {
    padding-left:0px;
    padding-right:0px;
    p {
      padding:0px;
    }
    h4 {
      font-size:1.12rem !important;
      line-height:25px;
    }
  }
  a :hover{
    text-decoration: underline;
  }
}
`;

const container = css`
  padding-top: 2vh;
  padding: 8px;
`;

const About = ({ ...props }) => {
  const { t } = useTranslation();
  const { pageContext } = props;

  const data = pageContext.pageData ? pageContext.pageData : null;

  if (!data) {
    return;
  }

  const programLink = pageContext.programUrl;

  const headerLinks = getHeaderLInks('', programLink, data.noLinks);

  const { pageHeader, headerLogo, headerLogoScroll } = (data || {}).node || {};

  const logos = pageContext.logos || [];
  const footerLogos = pageContext.footerLogos || [];

  const footerLinks = [
    {
      name: 'FAQ',
      link: programLink + '/faq',
    },
    {
      name: 'About',
      link: programLink + '/about',
    },
    {
      name: 'Contact Us',
      link: programLink + '/contactUs',
    },
    {
      name: 'Disclaimer',
      link: programLink + '/disclaimer',
    },
  ];

  const helmetLink = `${Constants.appUrl}/about`;

  let disclaimer_section_1_desc =
    t('disclaimer_section_1_desc', {
      returnObjects: true,
    }) || [];

  let disclaimer_section_2_desc =
    t('disclaimer_section_2_desc', {
      returnObjects: true,
    }) || [];

  let disclaimer_section_3_desc =
    t('disclaimer_section_3_desc', {
      returnObjects: true,
    }) || [];

  let disclaimer_section_4_desc =
    t('disclaimer_section_4_desc', {
      returnObjects: true,
    }) || [];

  let disclaimer_section_5_desc =
    t('disclaimer_section_5_desc', {
      returnObjects: true,
    }) || [];

  let disclaimer_section_6_desc =
    t('disclaimer_section_6_desc', {
      returnObjects: true,
    }) || [];

  let disclaimer_section_7_desc =
    t('disclaimer_section_7_desc', {
      returnObjects: true,
    }) || [];

  let disclaimer_section_8_desc =
    t('disclaimer_section_8_desc', {
      returnObjects: true,
    }) || [];

  let disclaimer_section_9_desc =
    t('disclaimer_section_9_desc', {
      returnObjects: true,
    }) || [];

  let disclaimer_section_10_desc =
    t('disclaimer_section_10_desc', {
      returnObjects: true,
    }) || [];

  let disclaimer_section_11_desc =
    t('disclaimer_section_11_desc', {
      returnObjects: true,
    }) || [];

  return (
    <Layout
      programLink={programLink}
      title={t('disclaimer_title')}
      content={[]}
      headerLinks={headerLinks}
      footerLinks={footerLinks}
      logos={logos}
      footerLogos={footerLogos}
      pageHeader={pageHeader}
      headerLogo={headerLogo}
      headerLogoScroll={headerLogoScroll}
      imageName="diss.jpg"
      visitedLinks={[{ url: '/', key: 'home', name: 'Home' }]}
      currentPage={{ key: 'disclaimer' }}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t('Investment Promotion Portal Disclaimer')}</title>
        <link rel="canonical" href={helmetLink} />
      </Helmet>
      <div className="container" style={{ padding: '0px 30px' }}>
        <GridContainer className="projects-container">
          <span css={ParagraphContent}>
            {/* <h1>Disclaimer</h1> */}

            <h2>
              <strong>{t('terms_conditions')}</strong>
            </h2>
            <div>
              <h4>
                <strong>{t('disclaimer_section_1_title')}</strong>
              </h4>
              {isArray(disclaimer_section_1_desc) &&
                !isEmpty(disclaimer_section_1_desc) &&
                disclaimer_section_1_desc.map((ele, index) => {
                  return <p key={index}>{ele}</p>;
                })}
            </div>

            <div>
              <h4>
                <strong>{t('disclaimer_section_2_title')}</strong>
              </h4>
              {isArray(disclaimer_section_2_desc) &&
                !isEmpty(disclaimer_section_2_desc) &&
                disclaimer_section_2_desc.map((ele, index) => {
                  return <p key={index}>{ele}</p>;
                })}
            </div>

            <div>
              <h4>
                <strong>{t('disclaimer_section_3_title')}</strong>
              </h4>
              {isArray(disclaimer_section_3_desc) &&
                !isEmpty(disclaimer_section_3_desc) &&
                disclaimer_section_3_desc.map((ele, index) => {
                  return <p key={index}>{ele}</p>;
                })}
            </div>

            <div>
              <h4>
                <strong>{t('disclaimer_section_4_title')}</strong>
              </h4>
              {isArray(disclaimer_section_4_desc) &&
                !isEmpty(disclaimer_section_4_desc) &&
                disclaimer_section_4_desc.map((ele, index) => {
                  return <p key={index}>{ele}</p>;
                })}
            </div>

            <div>
              <h4>
                <strong>{t('disclaimer_section_5_title')}</strong>
              </h4>
              {isArray(disclaimer_section_5_desc) &&
                !isEmpty(disclaimer_section_5_desc) &&
                disclaimer_section_5_desc.map((ele, index) => {
                  return <p key={index}>{ele}</p>;
                })}
            </div>

            <div>
              <h4>
                <strong>{t('disclaimer_section_6_title')}</strong>
              </h4>
              {isArray(disclaimer_section_6_desc) &&
                !isEmpty(disclaimer_section_6_desc) &&
                disclaimer_section_6_desc.map((ele, index) => {
                  return <p key={index}>{ele}</p>;
                })}
            </div>

            <div>
              <h2>
                <strong>{t('disclaimer_section_7_title')}</strong>
              </h2>
              {isArray(disclaimer_section_7_desc) &&
                !isEmpty(disclaimer_section_7_desc) &&
                disclaimer_section_7_desc.map((ele, index) => {
                  return (
                    <p
                      key={index}
                      dangerouslySetInnerHTML={{ __html: ele }}
                    ></p>
                  );
                })}
            </div>

            <div>
              <h4>
                <strong>{t('disclaimer_section_8_title')}</strong>
              </h4>
              {isArray(disclaimer_section_8_desc) &&
                !isEmpty(disclaimer_section_8_desc) &&
                disclaimer_section_8_desc.map((ele, index) => {
                  return (
                    <p
                      key={index}
                      dangerouslySetInnerHTML={{ __html: ele }}
                    ></p>
                  );
                })}
            </div>

            <div>
              <h4>
                <strong>{t('disclaimer_section_9_title')}</strong>
              </h4>
              {isArray(disclaimer_section_9_desc) &&
                !isEmpty(disclaimer_section_9_desc) &&
                disclaimer_section_9_desc.map((ele, index) => {
                  return <p key={index}>{ele}</p>;
                })}
            </div>

            <div>
              <h4>
                <strong>{t('disclaimer_section_10_title')}</strong>
              </h4>
              {isArray(disclaimer_section_10_desc) &&
                !isEmpty(disclaimer_section_10_desc) &&
                disclaimer_section_10_desc.map((ele, index) => {
                  return (
                    <p
                      key={index}
                      dangerouslySetInnerHTML={{ __html: ele }}
                    ></p>
                  );
                })}
            </div>

            <div>
              <h4>
                <strong>{t('disclaimer_section_11_title')}</strong>
              </h4>
              {isArray(disclaimer_section_11_desc) &&
                !isEmpty(disclaimer_section_11_desc) &&
                disclaimer_section_11_desc.map((ele, index) => {
                  return (
                    <p
                      key={index}
                      dangerouslySetInnerHTML={{ __html: ele }}
                    ></p>
                  );
                })}
            </div>
          </span>
        </GridContainer>
      </div>
    </Layout>
  );
};

export default About;

About.propTypes = {
  center: PropTypes.object,
  pageContext: PropTypes.object,
  location: PropTypes.object,
};
